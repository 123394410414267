<!--Footer Starts-->
<footer class="footer"  [style.backgroundColor]="FooterBackgroundColor" [style.color]="FooterTextColor" class='customfootercss d-flex text-end'>
  <p class="copyright clearfix ml-auto my-auto mr-2">
    {{this.MainPageFooter}}
    <span class="d-none d-sm-inline-block"></span>
  </p>
   
  <!-- <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="NILRx"
      href="#">NILRx</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p> -->
</footer>
<!--Footer Ends-->