import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from 'app/page/page.component';
//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [ 
   {
    path: '',
    component:PageComponent,
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: ':slug',
    component:PageComponent,
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'pages/faq',
    loadChildren: () => import('../../pages/faq/faq.module').then(m => m.FaqModule)
  }
];
