<section style="min-height: 95vh; scroll-margin: 80px;">
  <section class="faq-wrapper">
    <div class="faq-search">
      <div class="row">
        <div class="col-12">
          <div class="card faq-bg bg-transparent box-shadow-0">
            <div class="card-content">
              <div class="card-body p-md-5">
                <h2 class="faq-title text-center mb-3">{{this.HomeText}}
                </h2>
                <form [formGroup]="myForm" id="myform" class="container justify-content-center col-12 m-auto mx-auto"
                  style="background: rgb(0 0 0 / 10%); padding: 0.5rem 1rem 0rem 1rem"
                  (ngSubmit)="onFormSubmit($event)">
                  <div class="form-row">
                    <div class="col-md-3 form-group">
                      <label for="validationTooltip01">Drug</label>
                      <div class="ng-autocomplete">
                        <ng-autocomplete [data]="data" [isLoading]="isLoading" [minQueryLength]="2" [debounceTime]="500"
                          [searchKeyword]="keyword" (selected)='selectEvent($event)'
                          (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate" class='customautocomplete'
                          [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.MedDrugName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>

                      <div class="" *ngIf="MedicineInValid">
                        <!-- <div class="invalid-feedback d-block" *ngIf="MedicineInValid"> -->
                        <span id="MedicineInvalidState" style="color: #F55252;">Please select a Drug.</span>
                      </div>

                    </div>
                    <div class="col-md-3 form-group">
                      <label for="validationTooltip02">Radius</label>
                      <fieldset class="form-group">
                        <select id="users-list-verified" formControlName="radius" class="form-control bg-white zipcode">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="5">5</option>
                          <option selected value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </fieldset>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="validationTooltip03">Zip code</label>
                      <input type="text" min='0' formControlName="zipcode" class="form-control bg-white zipcode noNumber"
                        [ngClass]="{ 'is-invalid': zipcodeInValid}" id="validationTooltip03" min="0" maxlength="10"  (wheel)="preventScroll($event)" oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                      <div class="invalid-feedback">
                        <span id="zipInvalidState">This is invalid state.</span>
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="validationTooltip03" style="visibility: hidden;">ZIP</label>
                      <button class="btn btn-primary btn-block custombtn" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
                <div *ngIf="searchHistory.length >= 1" class="mt-2">
                  <h5>Recent Searches</h5>
                  <div class="d-flex flex-wrap">
                    <span class="badge bg-light-primary mb-1 mr-2 cr-pointer"
                      *ngFor="let item of searchHistory.reverse()"><span (click)="setValue($event)">{{ item.MedDrugName
                        }}</span><i class="fa fa-times ml-1" (click)="ClearSelectedVal($event)"></i><span></span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Map -->


  <section class="faq-wrapper mt-4 mb-4 container" id="PharamcySection"
    *ngIf="res_pricing && res_pricing.length>0 && error == false">
    <div class="faq-search">
      <div class="row">
        <div class="col-lg-6 col-12">
          <app-modal-popup [modalTitle]="'Edit Prescription'" (modalClosed)="closeResult = $event"></app-modal-popup>

          <button type="button" (click)="modalPopup.open(content); setModalValue()"
            class="btn btn-light mb-4 btn-block prescription_button cr-pointer">
            <div class="d-flex flex-column">
              <span class="Prescription mr-auto">Prescription</span>
              <h3 class="text-left custom_heading mb-0 p-0">{{selectedDrugObj.MedDrugName}}
                <span class=""> {{selectedDrugObj.MedStrength || ''}}{{selectedDrugObj.Uom?.toLowerCase() || ''}}</span>
                <span class="tabLetter mx-1">
                  {{selectedMedPack?selectedMedPack:selectedDrugObj.MedPackSize}}
                  {{selectedDrugObj.DosageForm.toLowerCase()}}
                </span>
              </h3>
            </div>
            <i class="ft-edit float-right my-auto" style="font-size: 30px; color: black;"></i>
          </button>


          <div class="row d-flex my-2">
            <div class="col-md-12 flex-fill custom_btn_row">
              <button type="button" *ngFor="let pitem of res_pricing.slice(0, 9); let i = index"
                [ngClass]="{ 'selected-button': selectedButtonIndex === i }" (click)="handleButtonClick(i, pitem)"
                class="custom_button btn btn-light cr-pointer position-relative">
                <div display="inline-block">
                  <span *ngIf="pitem.LowestPrice" class="badge badge-primary custompricebadge">Low Price</span>
                  <img alt="{{processPharmacyName(pitem['Pharmacy'].Name)}}"
                    [src]="getPharmacyImageURL(pitem['Pharmacy'].Name)" class="object-fit-image" height="28" width="28">
                </div>
                <span class="custom_pharmacy_name">{{ (pitem['Pharmacy'].Name) }}</span>
               <span *ngIf="pitem['Pricing'].PatientPay < 999999" class="custom_pharmacy_price">{{ formatUSD(pitem['Pricing'].PatientPay) }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <div class="card mt-0">
            <div class="card-body">
              <div class="card card-outline-primary box-shadow-0 text-center">
                <div class="card-content p-2">
                  <div class="d-flex justify-content-between">
                    <img [src]="logoUrl" alt="Client Logo" class="" height='70px' *ngIf="logoUrl !== null">

                    <!-- <img src="{{pharmalogo}}" alt="Pharma Logo" class="p-1" height='70px'> -->
                  </div>
                  <div class="row card-border-bottom pt-3 pb-2">
                    <div class="col-6 text-left">
                      <p class="mb-1"><b>BIN:</b> {{FAQdata.Bin}}</p>
                      <p class="mb-1"><b>PCN:</b> {{FAQdata.Pcn}}</p>
                      <p class="mb-1"><b>Group ID:</b> {{FAQdata.GroupNum}}</p>
                      <p class="mb-1"><b>Member ID:</b> 10 digit phone #</p>
                      <!-- <p class="mb-1"><b>Member ID:</b> {{randomMemberID}}</p> -->
                    </div>
                    <div class="col-6 text-left">
                      <p class="mb-1"><b>{{selectedDrugObj.MedDrugName}}</b>
                        <span class="tabLetter"> {{selectedDrugObj.MedStrength
                          ||''}}{{selectedDrugObj.Uom?.toLowerCase() || ''}}</span>
                      </p>
                      <p class="mb-1"><b>Qty:</b>
                        {{selectedMedPack?selectedMedPack:selectedDrugObj.MedPackSize}}
                        {{selectedDrugObj.DosageForm.toLowerCase()}}
                      </p>
                      <p *ngIf="patientPay < 999999" class="mb-1"><b>{{formatUSD(patientPay)}}</b></p>
                    </div>
                  </div>
                  <p class="card-text text-left m-1"> <b>Help Desk Phone Number:</b> {{FAQdata.HelpDeskPhone}}</p>
                  <!-- <div class="card-inside-card-primary m-1">
                    <img src="assets/img/logos/logo-white.png" alt="Client Logo" class="" height='70px'>
                    <h5 class="card-title text-white mb-0">Supporting Your Student-Athletes!</h5>
                  </div> -->
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <button type="button" (click)="openPrintWindow()" class="btn btn-primary mb-1"><i
                    class="fa fa-print mr-1"></i>Print</button>


                <!-- modal start -->
                <ng-template #content let-c="close" let-d="dismiss">
                  <div class="modal-header">
                    <h4 class="modal-title">Edit Prescription</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <!-- <fieldset class="form-group">
                      <label for="MedicationOptions">Medication options</label>
                      <select class="form-control" id="MedicationOption">
                        <option>Empty for now</option>
                      </select>
                    </fieldset> -->
                    <fieldset class="form-group" *ngIf="uniqueMedStrengths.length > 0">
                      <label for="Dosage">Dosage</label>
                      <select class="form-control" id="Dosage" [(ngModel)]="selectedMedStrength"
                        (change)="updateDosage()" [disabled]="uniqueMedStrengths.length == 1">
                        <option *ngFor="let a of uniqueMedStrengths">{{a}}</option>
                      </select>
                    </fieldset>
                    <fieldset class="form-group" *ngIf="uniqueDosageForms.length > 0">
                      <label for="MedicineForm">Form</label>
                      <select class="form-control" id="MedicineForm" [(ngModel)]="selectedDosageForm"
                        (change)="updateform()" [disabled]="uniqueDosageForms.length <= 1">
                        <option *ngFor="let a of uniqueDosageForms">{{a}} </option>
                      </select>
                    </fieldset>
                    <!-- <ng-select [items]="cities" bindLabel="Form" placeholder="Select city" [(ngModel)]="selectedForm">
                    </ng-select> -->
                    <!-- <ng-select [items]="cities" bindLabel="Dosage" placeholder="Select city" [(ngModel)]="selectedDosage">
                    </ng-select> -->

                    <fieldset class="form-group">
                      <label for="Quantity">Quantity</label>
                      <input type="number" min='0' [(ngModel)]="selectedMedPackSize"
                        [ngClass]="{ 'is-invalid': QutInValid}" pattern="[0-9]*\.?[0-9]*" class="form-control"  (wheel)="preventScroll($event)">

                      <div class="invalid-feedback">
                        <span id="Quantity">Please Enter a Quantity</span>
                      </div>
                      <!-- <select class="form-control" [(ngModel)]="selectedMedPackSize" id="Quantity"
                        [disabled]="uniqueMedPackSizes.length <=1">
                        <option *ngFor="let a of uniqueMedPackSizes">{{a}}</option>
                      </select> -->
                    </fieldset>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="c('Close click')">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="submitModalForm()">Update</button>
                  </div>
                </ng-template>
                <!-- modal end -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Table Start -->
  <section class="container">
    <div id="variable-width-content" class="row" *ngIf="res_pricing && res_pricing.length>0 && error == false">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <th>
              <h3>Local Pharmacy Prices</h3>
              <p>Choose a pharmacy to get a coupon</p>
            </th>
          </div>
          <button type="button" class="btn btn-gray py-2" *ngFor="let pitem of res_pricing.slice(0, 100); let i = index"
            style="border-top: .5px solid;border-radius: 0px; color: gray; margin-bottom: 5px;">
            <div class="card-content">
              <div class="container">
                <div class="row justify-content-md-center" style="padding: 2px;" (click)="handleButtonClicks(i, pitem)">
                  <div class="col col-lg-4">
                    <div class="d-flex justify-content-md-start ">
                      <img [src]="getPharmacyImageURL(pitem['Pharmacy'].Name)" class="object-fit-image pull-left mr-2"
                        height="35" width="35">
                      <span class="align-self-center fw-500" style="color: black;">
                        {{ (pitem['Pharmacy'].Name) }}
                      </span>
                    </div>
                  </div>
                  <div class="col col-lg-4 d-flex justify-content-md-center">
                    <span *ngIf="pitem['Pricing'].PatientPay < 999999"  style="color: black;"><strong>{{
                        formatUSD(pitem['Pricing'].PatientPay)
                        }}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </section>
  <ngb-alert type="danger" class="mb-2" [dismissible]="false" id="ApierrorAlert" *ngIf="error == true">
    <div class="alert-icon-left d-flex">
      <i class="ft-alert-octagon mr-2"></i>
      <span class="mx-auto">{{errorMessage}}</span>
      <i class="fa fa-times cr-pointer" (click)="ClearAllData()"></i>
    </div>
  </ngb-alert>
  <ngx-spinner></ngx-spinner>
  <section>
    <p class="disclaimerText">{{Disclaimer}}</p>
  </section>
</section>