import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({ 
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent {
  @Input() modalTitle: string;
  @Output() modalClosed = new EventEmitter<string>();
  

  constructor(private modalService: NgbModal) {}

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.modalClosed.emit(`Closed with: ${result}`); 
      },
      (reason) => {
        this.modalClosed.emit(`Dismissed ${this.getDismissReason(reason)}`);
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
