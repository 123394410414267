import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable} from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  RequestInit: { method: any; headers: HttpHeaders; redirect: string; mode: string; } | undefined;
  constructor(private http: HttpClient) { }
  private configData :any;
  private apiUrl :string;
  private apiKey :string;
  private clientBlobName :string;
  private logoGetUrl = 'api/Blob?blobPath=RxLogic/Documents/Upload/Portal/';
  private FavGetUrl = 'api/Blob?blobPath=RxLogic/Documents/Upload/Portal/FavIconPath/';
  imageSrc: string | ArrayBuffer | null = null;

  getDrugData(name: string): Observable<any> { 
    const headers = new HttpHeaders({ 'Accept': 'application/json', 'apiKey': this.apiKey });
    return this.http.get(`${this.apiUrl}/DrugData?name=${name}`, { headers, responseType: 'text' });
  }
  fetchFAQ(): Observable<any> {
    return new Observable((observer) => {
      // Fetch config.json
      this.http.get('assets/data/config.json').subscribe(
        (data: any) => {
          this.configData = data;
          this.apiUrl = this.configData.ClientAPI.Uri;
          this.apiKey = this.configData.ClientAPI.apiKey;
          this.clientBlobName = this.configData.ClientAPI.ClientBlobName;
          this.FavGetUrl = `api/Blob?blobPath=${this.clientBlobName}/Documents/Upload/Portal/FavIconPath/`;
          this.logoGetUrl = `api/Blob?blobPath=${this.clientBlobName}/Documents/Upload/Portal/`;
          // Once config.json is fetched, make the API request
          const headers = new HttpHeaders({
            Accept: 'application/json',
            apiKey: this.apiKey,
          });

          let pathname = window.location.pathname.split('/')[1];
          if (pathname === 'pages' && localStorage.getItem('ClientURL')) {
            pathname = localStorage.getItem('ClientURL');
            this.makeApiRequest(`${this.apiUrl}/NILRX?route=${pathname}`, headers, observer);
          } else if (pathname) {
            this.makeApiRequest(`${this.apiUrl}/NILRX?route=${pathname}`, headers, observer);
          } else {
            this.makeApiRequest(`${this.apiUrl}/NILRX?route=TEST`, headers, observer);
          }
        },
        (error) => {
          console.error('Error fetching config.json:', error);
          observer.error(error);
        }
      );
    });
  }

  private makeApiRequest(url: string, headers: HttpHeaders, observer: any): void {
    this.http.get(url, { headers, responseType: 'text' }).subscribe(
      (data) => {
        observer.next(data);
        observer.complete();
      },
      (error) => {
        console.error('Error making API request:', error);
        observer.error(error);
      }
    );
  }

  fetchLogo(id: string, LogoPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (LogoPath) {
        const headers = new HttpHeaders().set('apiKey', this.apiKey);
  
        const requestOptions = {
          headers: headers,
          responseType: 'blob' as 'json', // Response type is 'blob'
        };
  
        this.http.get(`${this.apiUrl}${this.logoGetUrl}${id}/${LogoPath}`, requestOptions)
          .subscribe(
            (blob: Blob) => { 
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64 = this.getBase64StringFromDataURL(reader.result as string);
                localStorage.setItem('LogoPath', base64);
                resolve(base64); // Resolve the Promise with the base64 string
              };
              reader.onerror = (error) => {
                console.error('Error:', error);
                reject(error); // Reject the Promise on error
              };
              reader.readAsDataURL(blob);
            },
            error => {
              console.error('Error:', error);
              reject(error); // Reject the Promise on error
            }
          );
      } else {
        console.log('No Logo Path Found');
        reject('No Logo Path Found'); // Reject the Promise if LogoPath is empty
      }
    });
  }
  
  fetchFav(id: string, LogoPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (LogoPath) {
        const headers = new HttpHeaders().set('apiKey', this.apiKey);
  
        const requestOptions = {
          headers: headers,
          responseType: 'blob' as 'json', // Response type is 'blob'
        };
  
        this.http.get(`${this.apiUrl}${this.FavGetUrl}${id}/${LogoPath}`, requestOptions)
          .subscribe(
            (blob: Blob) => { 
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64 = this.getBase64StringFromDataURL(reader.result as string);
                localStorage.setItem('FavPath', base64);
                const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
                if (link) {
                  link.href = `data:image/png;base64,${base64.split("base64,")[1]}`;
                } else {
                  const newLink = document.createElement("link");
                  newLink.rel = "icon";
                  newLink.type = "image/x-icon";
                  newLink.href = `data:image/png;base64,${base64}`;
                  document.head.appendChild(newLink);
                }
                resolve(base64); // Resolve the Promise with the base64 string
              };
              reader.onerror = (error) => {
                console.error('Error:', error);
                reject(error); // Reject the Promise on error
              };
              reader.readAsDataURL(blob);
            },
            error => {
              console.error('Error:', error);
              reject(error); // Reject the Promise on error
            }
          );
      } else {
        console.log('No Logo Path Found');
        reject('No Logo Path Found'); // Reject the Promise if LogoPath is empty
      }
    });
  }
  
  getBase64StringFromDataURL(dataURL: string): string {
    return dataURL;
  }

  getDrugPricing(body:any): Observable<any> {
    const headers = new HttpHeaders({ 'Accept': 'application/json', 'apiKey': this.apiKey });
    return this.http.post(`${this.apiUrl}/PharmacyRadiusPricing`, body, { headers, responseType: 'text' });
  }
   
}
