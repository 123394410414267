import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeData = new BehaviorSubject<any>({}); // Initial empty data

  constructor(private apiService: ApiService) {
    this.loadThemeData();
  }

  private async loadThemeData() {
    try {
      const data = await this.apiService.fetchFAQ().toPromise();
      if(data !== "null") {
      const logoUrl = await JSON.parse(data).ClientLogoPath ? JSON.parse(data).ClientLogoPath.split('&name=')[1] : null;
      const favUrl = await JSON.parse(data).FavIconPath ? JSON.parse(data).FavIconPath.split('&name=')[1] : null;
      try {
        const image = await this.apiService.fetchLogo(JSON.parse(data).Id, logoUrl);
        const fav = favUrl ? await this.apiService.fetchFav(JSON.parse(data).Id, favUrl) : "";
        // The base64 string is now available in the "image" variable
      } catch (error) {
        console.error('Error:', error);
      }
      const parsedData = JSON.parse(data);
      const settings = {
          ClientLogoText: parsedData.ClientLogoText || 'NILRx',
          MainPageFooter: parsedData.MainPageFooter || 'Made with ❤ by NILRx',
          HomeScreenText: parsedData.HomeScreenText || 'Supporting student-athletes while saving on your healthcare needs',
          Disclaimer: parsedData.Disclaimer || 'NILRx is not sponsored by or affiliated with any of the third-party brands identified. Trademarks, brands, logos, and copyrights are the property of their respective owners.',
          AboutUsURL: parsedData.AboutUsURL || 'https://www.nilrx.com/about-us',
          ContactUsURL: parsedData.ContactUsURL || 'https://www.nilrx.com/contact-us',
          BannerText: parsedData.BannerText || 'Welcome to NILRx',
          MainFAQDescription: parsedData.MainFAQDescription || 'Find quick answers about how NilRx streamlines price comparisons among multiple medical stores for your convenience',
          ...parsedData
      };
      localStorage.setItem('settings', JSON.stringify(settings));
      this.themeData.next(data);
    } else {
      let settings
      settings = localStorage.getItem('settings');
      if(!settings)  {
        const obj = {
          ClientLogoText: 'NILRx',
        MainPageFooter: 'Made with ❤ by NILRx',
        HomeScreenText: 'Supporting student-athletes while saving on your healthcare needs',
        Disclaimer: 'NILRx is not sponsored by or affiliated with any of the third-party brands identified. Trademarks, brands, logos, and copyrights are the property of their respective owners.',
        AboutUsURL: 'https://www.nilrx.com/about-us',
        ContactUsURL: 'https://www.nilrx.com/contact-us',
        BannerText: 'Welcome to NILRx',
        FavIconPath: '',
        Tagline: '',
        URL: '',
        MainFAQDescription: 'Find quick answers about how NilRx streamlines price comparisons among multiple medical stores for your convenience',
      };
      settings = JSON.stringify(obj)
    }
      this.themeData.next(settings);
    }
    } catch (error) {
      console.error('Error loading theme data:', error);
    }
  }

  getThemeDataObservable() {
    return this.themeData.asObservable();
  }
}
