import { Component, HostBinding, OnInit  } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent  implements OnInit {
    //Variables
    currentDate : Date = new Date();
    FooterBackgroundColor: any;
    FooterTextColor: any;
    MainPageFooter: string;
    constructor(private themeService: ThemeService) {}
    ngOnInit(): void {
    this.themeService.getThemeDataObservable().subscribe(data => {  
        if (Object.keys(data).length !== 0) {
         const commandata = JSON.parse(data);
         this.FooterBackgroundColor = commandata.FooterBackgroundColor;
         this.FooterTextColor = commandata.FooterTextColor;
         this.MainPageFooter = commandata.MainPageFooter || "Made with ❤ by NILRx      ";
        }  
    });
  }
}
