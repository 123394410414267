import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES } from './navigation-routes.config';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = 'Side'; 
  DefaultNilRxlogoUrl = 'assets/img/logos/logo.png';
  public logoUrl; 
  public altText;
  layoutSub: Subscription;
  HeaderBackgroundColor: any;
  HeaderTextColor: any;

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private themeService: ThemeService) {
    this.config = this.configService.templateConf;
  }

  changeColor(e) {
    this.menuItems.forEach((object) => {
      object.class = object.class.split(" ").filter(str => str !== "active").join(" ");
    })
    if (e.currentTarget.innerText == 'Price Check') {
      const url = localStorage.getItem('ClientURL')
      if(url){
        window.history.pushState({}, null, `/${url.toLowerCase()}`)
      }
    }
  }
  ngOnInit() {
    this.menuItems = HROUTES;
    if (!window.location.pathname.includes('/pages')) {
      this.menuItems[0].class = this.menuItems[0].class + " active"
    }
    this.themeService.getThemeDataObservable().subscribe(data => {  
        if (Object.keys(data).length !== 0) {
         const commandata = JSON.parse(data);
         this.HeaderBackgroundColor = commandata.HeaderBackgroundColor;
         this.HeaderTextColor = commandata.HeaderTextColor;
         //this.logoUrl = commandata.ClientLogoPath;  
         this.menuItems[2].path = commandata.ContactUsURL || 'https://www.nilrx.com/contact-us'
         this.menuItems[3].path = commandata.AboutUsURL || 'https://www.nilrx.com/about-us'
        }
        const imageBAse64 = localStorage.getItem('LogoPath')
        this.logoUrl = imageBAse64 ? imageBAse64 : null
        this.altText = localStorage.getItem('ClientLogoText') ? localStorage.getItem('ClientLogoText') : ""
    });
  }

  ngAfterViewInit() {

    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }


    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

}
